import BigNumber from 'bignumber.js'
import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useRefresh from 'hooks/useRefresh'
import { fetchFarmsPublicDataAsync, fetchPoolsPublicDataAsync, fetchPoolsUserDataAsync } from './actions'
import { fetchLaunchPoolsUserDataAsync, fetchPoolsDataAsync } from './pools'
import { State, Farm, Pool } from './types'
import { QuoteToken } from '../config/constants/types'

const ZERO = new BigNumber(0)

export const useFetchPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync())
    // dispatch(fetchPoolsPublicDataAsync())
    // dispatch(fetchPoolsDataAsync()) 
  }, [dispatch, slowRefresh])
}

// Farms

export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data)
  // const farms = useSelector((state: State) => state.farms.data.find((f) => f.isVisible))
  return farms
}

export const useLaunchPools = (): Pool[] => {
  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid)

  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
  }
}


// Pools

export const usePools = (account): Pool[] => {
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const usePoolFromPid = (sousId): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId))
  return pool
}

// Prices

export const usePriceBnbBusd = (): BigNumber => {
  // const pid = 2 // BUSD-BNB LP
  // const farm = useFarmFromPid(pid)
  // return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
  return new BigNumber(10000000000000000000)
}

export const usePriceMashBusd = (): BigNumber => {
  // const pid = 0; // MASH-BUSD LP
  // const farm = useFarmFromPid(pid);
  // return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO;
  return new BigNumber(10000000000000000000)
}


export const usePricePussyDai = (): BigNumber => {
  // return new BigNumber(10000000000000000000)
  const pricePussyWpls = usePricePussyWpls()
  const wplsDaiPrice = usePriceWplsDai()
  return pricePussyWpls.times(wplsDaiPrice);
  // return new BigNumber(10000000000000000000)
  // return new BigNumber(10000000000000000000)
}

export const usePriceOgDai = (): BigNumber => {
  // return new BigNumber(10000000000000000000)
  const ogWplsPrice = usePriceOgWpls()
  const wplsDaiPrice = usePriceWplsDai()
  return ogWplsPrice.times(wplsDaiPrice);
  // return new BigNumber(10000000000000000000)
  // return new BigNumber(10000000000000000000)
}

export const usePriceOgWpls = (): BigNumber => {
  // return new BigNumber(10000000000000000000)
  const pid = 3; // 'OG-WPLS LP'
  const farm = useFarmFromPid(pid);
  // invert the value, because OG is the quotetoken, but we want it in terms of WPLS.
  return farm.tokenPriceVsQuote ?  new BigNumber(1).div(new BigNumber(farm.tokenPriceVsQuote)) : ZERO;
  // return new BigNumber(10000000000000000000)
  // return new BigNumber(10000000000000000000)
}

export const usePricePussyWpls = (): BigNumber => {
  // return new BigNumber(10000000000000000000)
  const pid = 0; // 'OG-WPLS LP'
  const farm = useFarmFromPid(pid);
  // invert the value, because PUSSY is the quotetoken, but we want it in terms of WPLS.
  return farm.tokenPriceVsQuote ?  new BigNumber(1).div(new BigNumber(farm.tokenPriceVsQuote)) : ZERO;
  // return new BigNumber(10000000000000000000)
  // return new BigNumber(10000000000000000000)
}

export const usePriceWplsDai = (): BigNumber => {
  // return new BigNumber(10000000000000000000)
  const pid = 99; // 'WPLS-DAI LP'
  const farm = useFarmFromPid(pid);
  return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO;
  // return new BigNumber(10000000000000000000)
  // return new BigNumber(10000000000000000000)
}

export const usePriceHexDai = (): BigNumber => {
  // return new BigNumber(10000000000000000000)
  const pid = 4; // 'HEX / WPLS'
  const farm = useFarmFromPid(pid);
  const hexWplsPrice = farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO;
  
  const wplsDaiPrice = usePriceWplsDai()
  return hexWplsPrice.times(wplsDaiPrice);
  
  // return new BigNumber(10000000000000000000)
  // return new BigNumber(10000000000000000000)
}

export const useTotalValue = (): BigNumber => {
  let value = new BigNumber(0)
  const totalFarmsValue = useFarmsValue()
  const totalLaunchPoolValue = useLaunchPoolValue()
  value = BigNumber.sum(totalFarmsValue, totalLaunchPoolValue)
  return value
}

export const useFarmsValue = () => {
  const farms = useFarms()
  // const bnbPrice = usePriceBnbBusd()
  // const mashPrice = usePriceMashBusd()
  
  const ogPriceUsd = usePriceOgDai()
  const pussyPriceUsd = usePricePussyDai()
  const wplsPriceUsd = usePriceWplsDai()
  const hexPriceUsd = usePriceHexDai()
  
  
  let value = new BigNumber(0)
  for (let i = 0; i < farms.length; i++) {
    const farm = farms[i]
    if (farm.lpTotalInQuoteToken) {
      let val;
      if (farm.quoteTokenSymbol === QuoteToken.OG) {
        val = (ogPriceUsd.times(farm.lpTotalInQuoteToken))
      } else if (farm.quoteTokenSymbol === QuoteToken.PUSSY) { // TODO: should be updated with quiteToken.MASH
        val = (pussyPriceUsd.times(farm.lpTotalInQuoteToken))
      } else if (farm.quoteTokenSymbol === QuoteToken.WPLS) { // TODO: should be updated with quiteToken.MASH
        val = (wplsPriceUsd.times(farm.lpTotalInQuoteToken))
      } else if (farm.quoteTokenSymbol === QuoteToken.HEX) { // TODO: should be updated with quiteToken.MASH
        val = (hexPriceUsd.times(farm.lpTotalInQuoteToken))
      } else {
        val = (farm.lpTotalInQuoteToken)
      }
      value = value.plus(val)
    }
  }

  return value
}

export const useLaunchPoolValue = () => {
  const launchPools = useLaunchPools();
  const bnbPrice = usePriceBnbBusd();
  const mashPrice = usePriceMashBusd();
  let value = new BigNumber(0);
  launchPools.forEach(launchPool => {
    if (launchPool.stakingTokenAddress) {
      let val;
      if (launchPool.stakingTokenName === QuoteToken.MASH) {
        val = mashPrice.times(launchPool.totalStaked).div(new BigNumber(10).pow(launchPool.tokenDecimals))
      } else if (launchPool.stakingTokenName === QuoteToken.BNB) {
        val = bnbPrice.times(launchPool.totalStaked).div(new BigNumber(10).pow(launchPool.tokenDecimals))
      } else {
        val = launchPool.totalStaked.div(new BigNumber(10).pow(launchPool.tokenDecimals))
      }
      value = value.plus(val);
    }
  })
  return value;
}
