import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 18,
    risk: 1,
	isVisible: true,
    isTokenOnly: true,
	// tokenDecimals: 18,
    lpSymbol: 'FURU',
    lpAddresses: {
      97: '',
      56: '', // HEX / WPLS LP
	  943: '',
		369: '0xD9AE5446eeb4a11F4EB49e3bd3d88a4Fd781De92', // FURU / WPLS
	// 369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39', // HEX
    },
    tokenSymbol: 'FURU',
    tokenAddresses: {
      97: '',
      56: '', 
	  943: '',
		369: '0x764F1A4e2D5A7D1A32aC0F8B8A109cE831C3dC66',
    },
    quoteTokenSymbol: QuoteToken.WPLS,
    quoteTokenAdresses: contracts.wpls,
	lpDecimals: 18,
	tokenDecimals: 18,
	quoteTokenDecimals: 18,
  },
  {
    pid: 19,
    risk: 1,
	isVisible: true,
    isTokenOnly: true,
	// tokenDecimals: 18,
    lpSymbol: 'YACHT',
    lpAddresses: {
      97: '',
      56: '', // HEX / WPLS LP
	  943: '',
		369: '0x8fB6D4E2284a1F100f802eFEF2507a1A3158e0b1', // YACHT / WPLS
	// 369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39', // HEX
    },
    tokenSymbol: 'YACHT',
    tokenAddresses: {
      97: '',
      56: '', 
	  943: '',
		369: '0xdB8E20f794Fa48d36Ae4988dC3D5Ba327df625f8',
    },
    quoteTokenSymbol: QuoteToken.WPLS,
    quoteTokenAdresses: contracts.wpls,
	lpDecimals: 18,
	tokenDecimals: 18,
	quoteTokenDecimals: 18,
  },
  {
    pid: 20,
    risk: 1,
	isVisible: true,
    isTokenOnly: true,
	// tokenDecimals: 18,
    lpSymbol: 'PEPE',
    lpAddresses: {
      97: '',
      56: '', // HEX / WPLS LP
	  943: '',
		369: '0x11659a752AC07452B9F139B897c917338e2dFB16', // PEPE / WPLS
	// 369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39', // HEX
    },
    tokenSymbol: 'PEPE',
    tokenAddresses: {
      97: '',
      56: '', 
	  943: '',
		369: '0x6982508145454Ce325dDbE47a25d4ec3d2311933',
    },
    quoteTokenSymbol: QuoteToken.WPLS,
    quoteTokenAdresses: contracts.wpls,
	lpDecimals: 18,
	tokenDecimals: 18,
	quoteTokenDecimals: 18,
  },
  {
    pid: 21,
    risk: 1,
	isVisible: true,
    isTokenOnly: true,
	// tokenDecimals: 18,
    lpSymbol: 'CST',
    lpAddresses: {
      97: '',
      56: '', // HEX / WPLS LP
	  943: '',
		369: '0x284a7654B90D3c2e217B6da9fAc010e6C4b54610', // CST / WPLS
	// 369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39', // HEX
    },
    tokenSymbol: 'CST',
    tokenAddresses: {
      97: '',
      56: '', 
	  943: '',
		369: '0x600136dA8cc6D1Ea07449514604dc4ab7098dB82',
    },
    quoteTokenSymbol: QuoteToken.WPLS,
    quoteTokenAdresses: contracts.wpls,
	lpDecimals: 18,
	tokenDecimals: 6,
	quoteTokenDecimals: 18,
  },
  {
    pid: 22,
    risk: 5,
	isVisible: true,
    lpSymbol: 'OG-CST LP',
    lpAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x842dCaff9b70fE72Ba64546BB1628ad5Cb0b057D',
    },
    tokenSymbol: 'CST',
    tokenAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x600136dA8cc6D1Ea07449514604dc4ab7098dB82'
    },
    quoteTokenSymbol: QuoteToken.OG,
    quoteTokenAdresses: contracts.og,
	lpDecimals: 18,
	tokenDecimals: 6,
	quoteTokenDecimals: 18,
  },
  {
    pid: 23,
    risk: 5,
	isVisible: true,
    lpSymbol: 'OG-HDRN LP',
    lpAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x5C45B825875ff98C4e10A3a22e738488794ab078',
    },
    tokenSymbol: 'HDRN',
    tokenAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x3819f64f282bf135d62168C1e513280dAF905e06'
    },
    quoteTokenSymbol: QuoteToken.OG,
    quoteTokenAdresses: contracts.og,
	lpDecimals: 18,
	tokenDecimals: 9,
	quoteTokenDecimals: 18,
  },
  {
    pid: 24,
    risk: 5,
	isVisible: true,
    lpSymbol: 'OG-YACHT LP',
    lpAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x58044DD1EFd6B4cace76ee70a5aE1c95Aa42546E',
    },
    tokenSymbol: 'YACHT',
    tokenAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0xdB8E20f794Fa48d36Ae4988dC3D5Ba327df625f8'
    },
    quoteTokenSymbol: QuoteToken.OG,
    quoteTokenAdresses: contracts.og,
	lpDecimals: 18,
	tokenDecimals: 18,
	quoteTokenDecimals: 18,
  },
  {
    pid: 26,
    risk: 5,
	isVisible: true,
    lpSymbol: 'PUSSY-CST LP',
    lpAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0xc1368EB45D91d094C9a4937b0aD2cD43D5A45304',
    },
    tokenSymbol: 'CST',
    tokenAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x600136dA8cc6D1Ea07449514604dc4ab7098dB82'
    },
    quoteTokenSymbol: QuoteToken.PUSSY,
    quoteTokenAdresses: contracts.pussy,
	lpDecimals: 18,
	tokenDecimals: 6,
	quoteTokenDecimals: 18,
  },
  {
    pid: 13,
    risk: 5,
	isVisible: true,
    lpSymbol: 'PUSSY-PLSX LP',
    lpAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x220cbC404198c101E0Cc806D31D439E72B2d27D9',
    },
    tokenSymbol: 'PLSX',
    tokenAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x95B303987A60C71504D99Aa1b13B4DA07b0790ab'
    },
    quoteTokenSymbol: QuoteToken.PUSSY,
    quoteTokenAdresses: contracts.pussy,
	lpDecimals: 18,
	tokenDecimals: 18,
	quoteTokenDecimals: 18,
  },
  {
    pid: 14,
    risk: 5,
	isVisible: true,
    lpSymbol: 'PUSSY-eHEX LP',
    lpAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x980A60Bfb8f794D2c0BfE1dd31b3fBe58bA9F7C6',
    },
    tokenSymbol: 'eHEX',
    tokenAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x57fde0a71132198BBeC939B98976993d8D89D225'
    },
    quoteTokenSymbol: QuoteToken.PUSSY,
    quoteTokenAdresses: contracts.pussy,
	lpDecimals: 18,
	tokenDecimals: 8,
	quoteTokenDecimals: 18,
  },
  {
    pid: 15,
    risk: 5,
	isVisible: true,
    lpSymbol: 'PUSSY-pHEX LP',
    lpAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x8c6731AfD36C8819f26CAc454b4f5C0eF1879d05',
    },
    tokenSymbol: 'pHEX',
    tokenAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39'
    },
    quoteTokenSymbol: QuoteToken.PUSSY,
    quoteTokenAdresses: contracts.pussy,
	lpDecimals: 18,
	tokenDecimals: 8,
	quoteTokenDecimals: 18,
  },
  {
    pid: 16,
    risk: 5,
	isVisible: true,
    lpSymbol: 'PUSSY-YACHT LP',
    lpAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0xF0af57913E9A209D12D7D6e1811aE912AC43e166',
    },
    tokenSymbol: 'YACHT',
    tokenAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0xdB8E20f794Fa48d36Ae4988dC3D5Ba327df625f8'
    },
    quoteTokenSymbol: QuoteToken.PUSSY,
    quoteTokenAdresses: contracts.pussy,
	lpDecimals: 18,
	tokenDecimals: 18,
	quoteTokenDecimals: 18,
  },
  {
    pid: 3,
    risk: 5,
	isVisible: true,
    lpSymbol: 'OG-WPLS LP',
    lpAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x4C8827D6b204814a19C51Cefd4CabA1aE5FB67DA',
    },
    tokenSymbol: 'WPLS',
    tokenAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27'
    },
    quoteTokenSymbol: QuoteToken.OG,
    quoteTokenAdresses: contracts.og,
	lpDecimals: 18,
	tokenDecimals: 18,
	quoteTokenDecimals: 18,
  },
  {
    pid: 0,
    risk: 5,
	isVisible: true,
    lpSymbol: 'PUSSY-WPLS LP',
    lpAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x269E4923C060EEB14E744ad379821dcfBc541d6a',
    },
    tokenSymbol: 'WPLS',
    tokenAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27'
    },
    quoteTokenSymbol: QuoteToken.PUSSY,
    quoteTokenAdresses: contracts.pussy,
	lpDecimals: 18,
	tokenDecimals: 18,
	quoteTokenDecimals: 18,
  },
  {
    pid: 1,
    risk: 5,
	isVisible: true,
    lpSymbol: 'PUSSY-HOA LP',
    lpAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x7c0549680645cc1C6100FD626242DaAc8C25790b',
    },
    tokenSymbol: 'HOA',
    tokenAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x7901a3569679AEc3501dbeC59399F327854a70fe'
    },
    quoteTokenSymbol: QuoteToken.PUSSY,
    quoteTokenAdresses: contracts.pussy,
	lpDecimals: 18,
	tokenDecimals: 18,
	quoteTokenDecimals: 18,
  },
  {
    pid: 2,
    risk: 5,
	isVisible: true,
    lpSymbol: 'PUSSY-GDAY LP',
    lpAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x9f5E9aFdA53afF1Fe936F9a4461B727734326c21',
    },
    tokenSymbol: 'GDAY',
    tokenAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x3981920A82d95A117A8747eCF9A11e105Ca38B62'
    },
    quoteTokenSymbol: QuoteToken.PUSSY,
    quoteTokenAdresses: contracts.pussy,
	lpDecimals: 18,
	tokenDecimals: 18,
	quoteTokenDecimals: 18,
  },
  
  {
    pid: 7,
    risk: 5,
	isVisible: true,
    lpSymbol: 'OG-INC LP',
    lpAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0xEeAC15f9C9bA91950b4D46205198bF7871413ADc',
    },
    tokenSymbol: 'INC',
    tokenAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x2fa878Ab3F87CC1C9737Fc071108F904c0B0C95d'
    },
    quoteTokenSymbol: QuoteToken.OG,
    quoteTokenAdresses: contracts.og,
	lpDecimals: 18,
	tokenDecimals: 18,
	quoteTokenDecimals: 18,
  },
  
  {
    pid: 8,
    risk: 5,
	isVisible: true,
    lpSymbol: 'eHEX-pHEX LP',
    lpAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x922723FC4de3122F7DC837E2CD2b82Dce9dA81d2',
    },
    tokenSymbol: 'eHEX',
    tokenAddresses: {
      97: '',
      56: '',
	  943: '',
	  369: '0x57fde0a71132198BBeC939B98976993d8D89D225'
    },
    quoteTokenSymbol: QuoteToken.HEX,
    quoteTokenAdresses: contracts.hex,
	lpDecimals: 18,
	tokenDecimals: 8,
	quoteTokenDecimals: 8,
  },
  
  {
    pid: 11,
    risk: 1,
	isVisible: true,
    isTokenOnly: true,
	// tokenDecimals: 18,
    lpSymbol: 'eHEX',
    lpAddresses: {
      97: '',
      56: '', // HEX / WPLS LP
	  943: '',
		369: '0xF0eA3efE42C11c8819948Ec2D3179F4084863D3F', // eHEX / WPLS LP
	// 369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39', // HEX
    },
    tokenSymbol: 'eHEX',
    tokenAddresses: {
      97: '',
      56: '', 
	  943: '',
		369: '0x57fde0a71132198BBeC939B98976993d8D89D225',
    },
    quoteTokenSymbol: QuoteToken.WPLS,
    quoteTokenAdresses: contracts.wpls,
	lpDecimals: 18,
	tokenDecimals: 8,
	quoteTokenDecimals: 18,
  },
  
  {
    pid: 12,
    risk: 1,
	isVisible: true,
    isTokenOnly: true,
	// tokenDecimals: 18,
    lpSymbol: 'eHEX',
    lpAddresses: {
      97: '',
      56: '', // HEX / WPLS LP
	  943: '',
		369: '0xF0eA3efE42C11c8819948Ec2D3179F4084863D3F', // eHEX / WPLS LP
	// 369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39', // HEX
    },
    tokenSymbol: 'eHEX',
    tokenAddresses: {
      97: '',
      56: '', 
	  943: '',
		369: '0x57fde0a71132198BBeC939B98976993d8D89D225',
    },
    quoteTokenSymbol: QuoteToken.WPLS,
    quoteTokenAdresses: contracts.wpls,
	lpDecimals: 18,
	tokenDecimals: 8,
	quoteTokenDecimals: 18,
  },
  
  {
    pid: 4,
    risk: 1,
	isVisible: true,
    isTokenOnly: true,
	// tokenDecimals: 18,
    lpSymbol: 'HEX',
    lpAddresses: {
      97: '',
      56: '', // HEX / WPLS LP
	  943: '',
		369: '0x19BB45a7270177e303DEe6eAA6F5Ad700812bA98', // HEX / WPLS LP
	// 369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39', // HEX
    },
    tokenSymbol: 'HEX',
    tokenAddresses: {
      97: '',
      56: '', 
	  943: '',
		369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39',
    },
    quoteTokenSymbol: QuoteToken.WPLS,
    quoteTokenAdresses: contracts.wpls,
	lpDecimals: 18,
	tokenDecimals: 8,
	quoteTokenDecimals: 18,
  },
  
  {
    pid: 5,
    risk: 1,
	isVisible: true,
    isTokenOnly: true,
	// tokenDecimals: 18,
    lpSymbol: 'PLSX',
    lpAddresses: {
      97: '',
      56: '', // HEX / WPLS LP
	  943: '',
		369: '0x149B2C629e652f2E89E11cd57e5d4D77ee166f9F', // PLSX / WPLS LP
	// 369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39', // HEX
    },
    tokenSymbol: 'PLSX',
    tokenAddresses: {
      97: '',
      56: '', 
	  943: '',
		369: '0x95B303987A60C71504D99Aa1b13B4DA07b0790ab',
    },
    quoteTokenSymbol: QuoteToken.WPLS,
    quoteTokenAdresses: contracts.wpls,
	lpDecimals: 18,
	tokenDecimals: 18,
	quoteTokenDecimals: 18,
  },
  
  {
    pid: 6,
    risk: 1,
	isVisible: true,
    isTokenOnly: true,
	// tokenDecimals: 18,
    lpSymbol: 'PUSSY',
    lpAddresses: {
      97: '',
      56: '', // HEX / WPLS LP
	  943: '',
		369: '0x269E4923C060EEB14E744ad379821dcfBc541d6a', // PUSSY / WPLS LP
	// 369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39', // HEX
    },
    tokenSymbol: 'PUSSY',
    tokenAddresses: {
      97: '',
      56: '', 
	  943: '',
		369: '0x733099917CbA30614e4b2ef1de8A551645665143',
    },
    quoteTokenSymbol: QuoteToken.WPLS,
    quoteTokenAdresses: contracts.wpls,
	lpDecimals: 18,
	tokenDecimals: 18,
	quoteTokenDecimals: 18,
  },
  
   {
    pid: 9,
    risk: 1,
	isVisible: true,
    isTokenOnly: true,
	// tokenDecimals: 18,
    lpSymbol: 'OG',
    lpAddresses: {
      97: '',
      56: '', // HEX / WPLS LP
	  943: '',
		369: '0x4C8827D6b204814a19C51Cefd4CabA1aE5FB67DA', // OG / WPLS LP
	// 369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39', // HEX
    },
    tokenSymbol: 'OG',
    tokenAddresses: {
      97: '',
      56: '', 
	  943: '',
		369: '0xF84b84dAAce6Ac00DbBAed26CA32Ff3570Aaf66C',
    },
    quoteTokenSymbol: QuoteToken.WPLS,
    quoteTokenAdresses: contracts.wpls,
	lpDecimals: 18,
	tokenDecimals: 18,
	quoteTokenDecimals: 18,
  },
  
   {
    pid: 10,
    risk: 1,
	isVisible: true,
    isTokenOnly: true,
	// tokenDecimals: 18,
    lpSymbol: 'NBC',
    lpAddresses: {
      97: '',
      56: '', // HEX / WPLS LP
	  943: '',
		369: '0x323467658EF6d82183F7887A988731b2A9BBd697', // NBC / WPLS LP
	// 369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39', // HEX
    },
    tokenSymbol: 'NBC',
    tokenAddresses: {
      97: '',
      56: '', 
	  943: '',
		369: '0xc9f523Fce37A28893A83Da68BbA03835D97Ae059',
    },
    quoteTokenSymbol: QuoteToken.WPLS,
    quoteTokenAdresses: contracts.wpls,
	lpDecimals: 18,
	tokenDecimals: 18,
	quoteTokenDecimals: 18,
  },
  
  {
    pid: 17,
    risk: 1,
	isVisible: true,
    isTokenOnly: true,
	// tokenDecimals: 18,
    lpSymbol: 'HOA',
    lpAddresses: {
      97: '',
      56: '', // HEX / WPLS LP
	  943: '',
		369: '0xD1A2518754796016F177E1759f4Ae50a4dcdA333', // HOA / WPLS LP
	// 369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39', // HEX
    },
    tokenSymbol: 'HOA',
    tokenAddresses: {
      97: '',
      56: '', 
	  943: '',
		369: '0x7901a3569679AEc3501dbeC59399F327854a70fe',
    },
    quoteTokenSymbol: QuoteToken.WPLS,
    quoteTokenAdresses: contracts.wpls,
	lpDecimals: 18,
	tokenDecimals: 18,
	quoteTokenDecimals: 18,
  },
  
  {
    pid: 99,
    risk: 5,
  isVisible: false,
    lpSymbol: 'WPLS-DAI',
    lpAddresses: {
      97: '',
      56: '',
    943: '0xA2D510bf42D2B9766DB186F44a902228E76ef262',
	369: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
    },
    tokenSymbol: 'WPLS',
    tokenAddresses: {
      97: '',
      56: '',
    943: '0x70499adEBB11Efd915E3b69E700c331778628707',
	369: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
    },
    quoteTokenSymbol: QuoteToken.DAI,
    quoteTokenAdresses: contracts.dai,
	lpDecimals: 18,
	tokenDecimals: 18,
	quoteTokenDecimals: 18,
  },
  
  
 // {
 //    pid: 98,
 //    risk: 5,
// isVisible: false,
 //    lpSymbol: 'XCAT-WPLS',
 //    lpAddresses: {
 //      97: '',
 //      56: '',
//   943: '0x121bF5D0A241e6f42a308F06D17675B0251BB51C',
 //    },
 //    tokenSymbol: 'XCAT',
 //    tokenAddresses: {
 //      97: '',
 //      56: '',
//   943: '0x0F4E9e9cec0d6F76602057f35626Af8A0d8B5c47',
 //    },
 //    quoteTokenSymbol: QuoteToken.WPLS,
 //    quoteTokenAdresses: contracts.wpls,
 //  },
  
  
  
  
  
  
  //  pid: 0,
  //   risk: 5,
  //   lpSymbol: 'MASH-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x87c182edb12f74d561519ab586205fe6cd75363a',
  //   },
  //   tokenSymbol: 'MASH',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x787732f27d18495494cea3792ed7946bbcff8db2',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  //    {
  //    pid: 20,
  //    risk: 5,
  //    lpSymbol: 'MASH-ETH LP',
  //    lpAddresses: {
  //      97: '',
  //      56: '0x548997391C670A5179AF731A30e7C3aDA2f483e7',
  //    },
  //    tokenSymbol: 'MASH',
  //    tokenAddresses: {
  //      97: '',
  //      56: '0x787732f27d18495494cea3792ed7946bbcff8db2',
  //    },
  //    quoteTokenSymbol: QuoteToken.ETH,
  //    quoteTokenAdresses: contracts.eth,
  //  },
  // {
  //   pid: 1,
  //   risk: 5,
  //   lpSymbol: 'MASH-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x7621886ac71e985dbea4f3f563bbb5a7865876a8',
  //   },
  //   tokenSymbol: 'MASH',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x787732f27d18495494cea3792ed7946bbcff8db2',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
  // {
  //   pid: 13,
  //   risk: 5,
  //   lpSymbol: 'MASH-BTCB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x0e70ff44229c6573cc020921345948ba4b5ec7cc',
  //   },
  //   tokenSymbol: 'MASH',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x787732f27d18495494cea3792ed7946bbcff8db2',
  //   },
  //   quoteTokenSymbol: QuoteToken.MASH,
  //   quoteTokenAdresses: contracts.cake,
  //   image: 'mash-btcb'
  // },
  // {
  //   pid: 14,
  //   risk: 5,
  //   lpSymbol: 'MASH-VAULT LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x9f8223b4b616aa9becb599c93b0430c6bef0443a',
  //   },
  //   tokenSymbol: 'MASH',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x787732f27d18495494cea3792ed7946bbcff8db2',
  //   },
  //   quoteTokenSymbol: QuoteToken.VAULT,
  //   quoteTokenAdresses: contracts.vault,
  // },
  // {
  //   pid: 15,
  //   risk: 5,
  //   lpSymbol: 'MASH-CAKE LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x16940bc578c30c7c10a2cf8a150b98a8b1cee152',
  //   },
  //   tokenSymbol: 'MASH',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x787732f27d18495494cea3792ed7946bbcff8db2',
  //   },
  //   quoteTokenSymbol: QuoteToken.MASH,
  //   quoteTokenAdresses: contracts.cake,
  //   image: 'mash-cake',
  // },
  // {
  //   pid: 21,
  //   risk: 5,
  //   lpSymbol: 'MASH-PLUM LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x9EC365D77dCF2b3230399a23D35aEF4318de710D',
  //   },
  //   tokenSymbol: 'MASH',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x787732f27d18495494cea3792ed7946bbcff8db2',
  //   },
  //   quoteTokenSymbol: QuoteToken.PLUM,
  //   quoteTokenAdresses: contracts.plum,
  //   depositUrl: 'https://exchange.pancakeswap.finance/#/add/0x787732f27d18495494cea3792ed7946bbcff8db2/0xdE8eD8c9480EA12D050182AA99630B235dE30f83'
  // },
  // {
  //   pid: 22,
  //   risk: 5,
  //   lpSymbol: 'MASH-SLIME LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xb442780739037577920857DaD91259416b72DE7a',
  //   },
  //   tokenSymbol: 'SLIME',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x23b06097F8FE2DD9D3dF094D3ee8319Daa8756c1',
  //   },
  //   quoteTokenSymbol: QuoteToken.MASH,
  //   quoteTokenAdresses: contracts.cake,
  //   depositUrl: 'https://dex.slime.finance/#/add/0x23b06097F8FE2DD9D3dF094D3ee8319Daa8756c1/0x787732f27d18495494cea3792ed7946bbcff8db2',
  //   image: 'mash-slime'
  // },
  // {
  //   pid: 23,
  //   risk: 5,
  //   lpSymbol: 'MASH-TAKO LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xC6D926086b29774b10530ab9e02980e9586a061F',
  //   },
  //   tokenSymbol: 'TAKO',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x2F3391AeBE27393aBa0a790aa5E1577fEA0361c2',
  //   },
  //   quoteTokenSymbol: QuoteToken.MASH,
  //   quoteTokenAdresses: contracts.cake,
  //   depositUrl: 'https://dex.apeswap.finance/#/add/0x2F3391AeBE27393aBa0a790aa5E1577fEA0361c2/0x787732f27d18495494cea3792ed7946bbcff8db2',
  //   image: 'mash-tako'
  // },
  // {
  //   pid: 2,
  //   risk: 3,
  //   lpSymbol: 'BNB-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
  //   },
  //   tokenSymbol: 'BNB',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 3,
  //   risk: 2,
  //   lpSymbol: 'BTCB-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x7561eee90e24f3b348e1087a005f78b4c8453524',
  //   },
  //   tokenSymbol: 'BTCB',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
  // {
  //   pid: 4,
  //   risk: 5,
  //   isTokenOnly: true,
  //   lpSymbol: 'MASH',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x87c182edb12f74d561519ab586205fe6cd75363a', // VIKING-BUSD LP
  //   },
  //   tokenSymbol: 'MASH',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x787732f27d18495494cea3792ed7946bbcff8db2',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 11,
  //   risk: 1,
  //   isTokenOnly: true,
  //   lpSymbol: 'BUSD',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x87c182edb12f74d561519ab586205fe6cd75363a', // VIKING-BUSD LP (BUSD-BUSD will ignore)
  //   },
  //   tokenSymbol: 'BUSD',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 10,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'WBNB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f', // BNB-BUSD LP
  //   },
  //   tokenSymbol: 'WBNB',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 12,
  //   risk: 2,
  //   isTokenOnly: true,
  //   lpSymbol: 'BTCB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xb8875e207ee8096a929d543c9981c9586992eacb', // BTCB-BUSD LP
  //   },
  //   tokenSymbol: 'BTCB',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 5,
  //   risk: 4,
  //   isTokenOnly: true,
  //   lpSymbol: 'CAKE',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x0ed8e0a2d99643e1e65cca22ed4424090b8b7458', // CAKE-BUSD LP
  //   },
  //   tokenSymbol: 'CAKE',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 6,
  //   risk: 4,
  //   isTokenOnly: true,
  //   lpSymbol: 'BUNNY',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xec7a69a3a5ee177c84855c86cc926ca0ba6275cc', // CAKE-BUSD LP
  //   },
  //   tokenSymbol: 'BUNNY',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 9,
  //   risk: 10,
  //   isTokenOnly: true,
  //   lpSymbol: 'VAULT',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xbeba8abb80bfa3c13ee4073d75a873e8a2b71dc3', // CAKE-BUSD LP
  //   },
  //   tokenSymbol: 'VAULT',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xd456be0ff7007b3d8ad656136487a23e771f5762',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
  // {
  //   pid: 8,
  //   risk: 4,
  //   isTokenOnly: true,
  //   lpSymbol: 'XVS',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x7d0ad8d3333814cf2ba2fb082dbc52d6b1a57c6e', // CAKE-BUSD LP
  //   },
  //   tokenSymbol: 'XVS',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 17,
  //   risk: 5,
  //   lpSymbol: 'USDC-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x680Dd100E4b394Bda26A59dD5c119A391e747d18',
  //   },
  //   tokenSymbol: 'USDC',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 18,
  //   risk: 5,
  //   lpSymbol: 'USDT-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xc15fa3E22c912A276550F3E5FE3b0Deb87B55aCd',
  //   },
  //   tokenSymbol: 'USDT',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x55d398326f99059ff775485246999027b3197955',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 24,
  //   risk: 5,
  //   lpSymbol: 'ETH-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xd9A0d1F5e02dE2403f68Bb71a15F8847A854b494',
  //   },
  //   tokenSymbol: 'ETH',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 25,
  //   risk: 5,
  //   lpSymbol: 'DOT-WBNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xbCD62661A6b1DEd703585d3aF7d7649Ef4dcDB5c',
  //   },
  //   tokenSymbol: 'DOT',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  //   image: 'dot-wbnb'
  // },
  // {
  //   pid: 19,
  //   risk: 5,
  //   lpSymbol: 'DOGE-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x1Efcb446bFa553A2EB2fff99c9F76962be6ECAC3',
  //   },
  //   tokenSymbol: 'DOGE',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xba2ae424d960c26247dd6c32edc70b295c744c43',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
]

export default farms
